import {Box, Flex, Img, Link} from "@chakra-ui/react";
import arrow from "/src/images/arrow.svg";
import * as React from "react";
import { Link as GatsbyLink } from "gatsby"

export default function PostPagination({props, count}) {

    const context = props.context ? props.context.page : 1
	const currentPage = context??1
	const postPerPage = process.env.GATSBY_POST_PER_PAGE
	const pageCount = Math.ceil(count/postPerPage)

	if (pageCount === 1) return (<><Box pb={'30px'} pt={'30px'}></Box></>);

	const isFirst = currentPage === 1;
	const isLast = currentPage === pageCount;
	const prev = currentPage === 2 ? '/blog/' :  `/blog/${currentPage - 1}/`;
	const next = currentPage + 1;

	const pages=[];
	let uri =''
	for (var i = 1; i <= pageCount; i++) {
        if(i === 1) uri = '/blog/'
        else uri = '/blog/' + i + '/'
		let html = '<span style="background: #9BCD66; color: white; width: 30px; height: 30px; display: flex; align-items: center; justifyContent: center;">'+i+'</span>'
		pages.push(<Link as={GatsbyLink} width={'40px'} h={'30px'} display={'flex'} alignItems={'center'} justifyContent={'center'} key={i} to={uri} dangerouslySetInnerHTML={{ __html: (currentPage===i)?html:i }}></Link>)
	}

	return (  
        <>
            <Flex maxW={'410px'} margin={'auto'} pb={'80px'} pt={'80px'} alignItems={'center'} justify={'center'} fontWeight={'bold'}>
			<Box w={'30px'} mr={'30px'}>
            {!isFirst && (
				<Link as={GatsbyLink} to={prev} rel="prev">
					<Img src={arrow} alt={'prev'} style={{ width: '30px', transform:'rotate(180deg)'}}/>
				</Link>
            )}
			</Box>
			<Flex alignItems={'center'}>{pages}</Flex>
			<Box w={'30px'} ml={'30px'}>
            {!isLast && (
				<Link as={GatsbyLink} to={`/blog/${next}`} rel="next">
					<Img src={arrow} alt={'next'} style={{ width: '30px'}}/>
				</Link>
			)}
			</Box>
            </Flex>
        </>
    )
}