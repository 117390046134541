import * as React from "react"
import {Box, Flex, Heading, Img} from "@chakra-ui/react";
import background from "/src/images/background.webp"
import arrowDown from "./images/arrow_down.svg"
import headBoxes from "./images/head_boxes.svg"

export default function Hero(props) {

    const scroll = () => {
        if(undefined === props.target) return false;
        const section = document.querySelector( '#'+props.target );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const page = props.page
    const job = props.job
    const subHeader = props.subtitle

    let title = ''
    let subheader = '';
    let link = '';
    let h1FontSize = '75px';
    let h1BaseFontSize = '40px';


    

    if(subHeader){
        subheader = <Box as='span' display='block' fontFamily="'essonnes-headline', serif" mb={{base: '0', md: '10px'}} fontSize={{base: '18px', md: '30px'}} fontWeight='bold' textAlign='center'>{subHeader}</Box>
        h1FontSize = '45px';
    }

    if(props.target){
        link = <Box
                    position="absolute"
                    left = "50%"
                    right = "50%"
                    transition = 'all 0.3s'
                    lineHeight = '60px'
                    bottom ={{ base: '0', md: '10px'}}
                    display = 'inline-block'
                    onClick = {scroll}
                    w = '20px'
                    _hover={{
                        textDecoration: 'none',
                    }}
                    sx={{ '&:hover img' : { top: '30px' } }}
                > &nbsp;
                    <Img style={{width:'14px', height: '31px', position: 'absolute', top: '4px', transition : 'all 0.3s'}} src={arrowDown} alt={'arrow_down'} />
                </Box>
    }

    if (page !== undefined){
        title = page.title;
    } else if (job !== undefined) {
        title = job.title;
    }

    if(title.length > 20) h1BaseFontSize = '20px'

    return (
        <Box position='relative'
             id="top"
        >
            <Flex
                position='absolute;'
                width='100%'
                justifyContent='space-between'
                height={'100%'}
                z-index='0'
            >
                <Box minHeight={{base: '250px',md: '400px'}} bg='#005B2E' backgroundImage={headBoxes} backgroundPosition='left top' width='100%'></Box>
                <Box minHeight={{base: '250px',md: '400px'}} width='100%' maxW = '410px' backgroundColor={'#0e9752'} backgroundImage={background} backgroundSize='cover'
                     backgroundPosition={'0 100%'}
                     position='relative'
                     _before={{
                         content: '" "',
                         backgroundColor: "#008F4700",
                         position: "absolute",
                         height: "100%",
                         width: "100%",
                         opacity: "0.85",
                         left: '0',
                         top: '0'
                     }}
                ></Box>


                <Box minHeight={{base: '250px',md:'400px'}} bg='#005B2E' backgroundImage={headBoxes} backgroundPosition='right bottom' width='100%'></Box>
            </Flex>
                <Box maxW={'1920px'}
                     padding={{ base: '110px 5% 20px 5%', md:'15% 0% 15% 0%',lg:'70px 15% 0 15%'}}
                     zIndex='3' position='relative' color='white'
                     minH={{base:'250px',md:'400px'}} display={'flex'}
                     flexDirection={'column'} justifyContent={{base:'flex-start',md: 'center'}}
                    m={'0 auto'}
                >
                    
                    <Heading as='h1'>

                            {subheader}
                            
                           <Box maxW={{base:'100%',md:'70%'}}
                                as='span' 
                                display='block'
                                margin='0 auto' textTransform='uppercase'
                                fontSize={{base: h1BaseFontSize,md:h1FontSize}}
                                textAlign='center'
                                lineHeight='1.3' fontFamily='Montserrat' fontWeight='bold'> {title}
                            </Box>
                 
                        
                    </Heading>
                    {link}
                </Box>
        </Box>
    )
}
