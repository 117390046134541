import * as React from "react"
import {graphql, useStaticQuery} from "gatsby"

import PostBox from "../../page/blog/postBox";
import { Grid, GridItem} from "@chakra-ui/react";
import PostPagination from "../../widgets/postPagination";

export default function BlogPageRecentPosts(props) {

	const data = useStaticQuery(graphql`
        query BlogPagePaginatedPosts_query {
          posts: allWpPost(sort: {date: DESC}) {
          nodes {
              id
              title
              excerpt
              slug
              uri
              language {
                  code
              }
			  date(fromNow: true, , locale: "pl")
			  dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
			  featuredImage {
				  node {
					  sizes
					  filename
					  publicUrl
					  localFile {
						  relativePath
						  childImageSharp {
							  id
							  gatsbyImageData(
								  placeholder: NONE
								  quality: 70
								  formats: [AUTO, WEBP, AVIF]
								  layout: FULL_WIDTH
							  )
						  }
					  }
				  }
			  }
              author {
				  node {
					name
				  }
				}
			  categories {
				  nodes {
					  uri
					  name
				  }
			  }
         	}
          }
        }
	`)
	const currentPage = props.context.page??1
	const postPerPage = process.env.GATSBY_POST_PER_PAGE
	const start = (currentPage-1)*postPerPage
	const end = currentPage*postPerPage
	const posts = data.posts.nodes.filter(post => post.language.code === props.page.language.code).slice(start,end)

    const postsData = data.posts.nodes.filter(post => post.language.code === props.page.language.code)

	return (
		<>
			<PostPagination props={props} count={postsData.length}/>
			<Grid templateColumns={{base:'1fr',md:'1fr', xl:'1fr 1fr'}} gap={6} maxW={'1120px'} m={'auto'}>
			{posts.map((node,i) => (
				<GridItem key={node.id} borderWidth='6px' bg='white' fontSize='15px' fontWeight='bold' position='relative' w={'100%'} maxW={'455px'}
					sx={{
						'&:nth-of-type(odd)': { justifySelf: { base: 'center', xl: 'end'} },
						'&:nth-of-type(even)': { justifySelf: {base: 'center', xl: 'start'} }
				}}
				>
					<PostBox key={node.id} node={node} i={i} />
				</GridItem>
			))}
			</Grid>
			<PostPagination props={props} count={postsData.length}/>
		</>
	)
}




