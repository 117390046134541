import * as React from "react"
import {useEffect, useState} from "react";
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import Seo from "../components/head/seo";
import {ChakraProvider,Container } from '@chakra-ui/react'
import Fonts from "../theme/fonts"
import theme from "../theme/theme"
import sal from '/node_modules/sal.js'

export default function Layout(props) {
	const node = props.node??null
	/*zostawiam changeBreakpoint na przyszłość, jakby menu miało się zmieniać*/
	const changeBreakpoint = '9999999999';
	const [scrollPosition, setScrollPosition] = useState(0);

	const isBrowser = typeof window !== "undefined"

	if (isBrowser){
		sal({
			disabled: (window.pageYOffset === 0)
		});
	}

	const handleScroll = (event) => {
		const position = window.pageYOffset;
		setScrollPosition(position);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const HeaderBg = ('post' === node.contentTypeName) ? 'transparent' : '#005B2E'

	return (
		<>
			<Fonts/>
			<ChakraProvider theme={theme}>
				<Seo node={node}/>
				<Container maxW='100%' paddingX='0' bg={HeaderBg}>
					<Header node={node} scrollPosition = {scrollPosition} changeBreakpoint={changeBreakpoint}/>
				</Container>

				<Container maxW='100%' overflow={'hidden'}>
					{props.children}
				</Container>

				<Container maxW='100%' paddingX='0'>
					<Footer />
				</Container>

			</ChakraProvider>
		</>
	)
}