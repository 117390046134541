import * as React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export default function FeaturedImage(props){

    const post = props.post;
    const image = post.featuredImage;
    const featuredImage = image ? getImage(image.node.localFile) : null

    return(
        featuredImage ? <GatsbyImage image={featuredImage} alt={post.title} style={{ margin: '50px auto', height: 'auto'}} /> : ''
    )
}