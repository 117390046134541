import * as React from "react"
import {Box, Flex} from "@chakra-ui/react";


export default function Stripes(props) {

    const bg1 = (props.bg1) ? props.bg1 : 'transparent'
    const bg2 = (props.bg2) ? props.bg2 : '#F4F4F4'
    const bg3 = (props.bg3) ? props.bg3 : 'transparent'
    const z0 = (props.z0 ) ? props.z0 : '0'
    const z1 = (props.z1 ) ? props.z1 : '-1'
    const z2 = (props.z2) ? props.z2 : '0'
    const z3 = (props.z3) ? props.z3 : '-1'
    const backgroundImage = (props.backgroundImage ) ? props.backgroundImage : ''
    const clickThrough = (props.clickThrough ) ? 'none' : 'auto'

    return (
         <Flex
            position='absolute;'
            height='100%'
            width='100%'
            justify-content='space-between'
            top='0'
            right='0'
            left='0'
            bottom='0'
            zIndex = {z0}
            pointerEvents={clickThrough}
        >
            <Box bg={bg1} width='100%' zIndex={z1}  />
            <Box bg={bg2} backgroundImage = {backgroundImage}
                 backgroundPosition={'center -50px'}
                 width='100%' maxW = '410px'
                 zIndex={z2} />
            <Box bg={bg3} width='100%' zIndex={z3}  />
        </Flex>

    )}