import * as React from "react"
import {Link} from "gatsby";
import {Box, Image, Heading} from '@chakra-ui/react'
import arrow from "/src/images/arrow.svg";
import FeaturedImage from "../../../components/common/featuredImage";

export default function PostBox(props) {
    const post = props.node

    //let img = (post.featuredImage && post.featuredImage.node) ? <Img src={post.featuredImage.node.publicUrl} alt={post.title} maxW={{ base: '100%', md: '375px'}} maxH={{ base: '100%', md: '190px'}} m={'auto'} w={'auto'}/> : '';
    let cat = (post.categories && post.categories.nodes) ? post.categories.nodes[0].name : '';
    let author = (post.author && post.author.node) ? post.author.node.name : '';

    return (
        <Box
            color='black'
            fontWeight='semibold'
            letterSpacing='wide'
            minH='100px'
            height={'100%'}
            p={{ base: '10px', md:'30px'}}
            pb={{ base: '50px', md:'100px'}}
            position={'relative'}
            transition = 'all 0.3s'
            sx={{ '&:hover .arrow' : { right: '15px' } }}
        >
            <Link to={post.uri}>
                <FeaturedImage post={post} />
                <Box mt={'34px'} mb={'15px'} color={'#32353833'} fontSize={'14px'} fontWeight={'semibold'}>{post.dateGmt} | {post.date}</Box>
                <Heading as='h2' fontFamily={'Montserrat'} fontSize={'15px'} fontWeight={'bold'} textTransform='uppercase' lineHeight={'26px'} mb={'15px'}>{post.title}</Heading>
                <Box color={'#323538'} fontSize={'14px'} fontWeight={'normal'}>{author}</Box>

                <Box position='absolute' className='arrow' transition = 'all 0.3s'
                     bottom = {{base: '10px',md:'37px'}}
                     right = {{base: '10px',md:'37px'}}>
                    <Image src={arrow} alt='Arrow right' width={'42px'} loading={'lazy'}/>
                </Box>
                <Box position='absolute' className='arrow' transition = 'all 0.3s'
                     bottom = {{base: '10px',md:'37px'}}
                     color = {'#9BCD66'}
                     textTransform='uppercase'
                     left = {{base: '10px',md:'30px'}}>
                    {cat}
                </Box>
            </Link>
        </Box>
    )
}