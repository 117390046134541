import * as React from "react"
import Layout from "../views/layouts/layout"
import PageBlog from "../views/templates/template.page.blog.pagin"

import {graphql} from "gatsby";

export default function BlogController(props) {
    const page = props.data.allWpPage.edges[0].node
    const context = props.pageContext
    
    return (

        <Layout node={page}>
            <PageBlog page={page} context={context}/>;    
        </Layout>
    )
}

export const pageQuery = (graphql`
  query BlogPageByTemplate{
    allWpPage(filter: {template: {templateName: {eq: "Blog"}}}) {
        edges {
            node {
                id
                uri
                title
                language {
                code
                }
            }
        }
    }
  }
`)