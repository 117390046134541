import * as React from "react"
import PageBlogPosts from "../components/page/blog/postsPagin";

import {graphql} from "gatsby";
import {Box} from "@chakra-ui/react";
import HeroShort from '../components/heroShort/hero'
import Stripes from "../components/common/stripes";
import Letter from "../components/common/letter";

export default function PageBlog(props)  {
	const page = props.page
	return (
		<>
			<HeroShort page={page} target={'posts'}/>
			<Box p={{ base: '20px 5% 20px 5%', md:'40px 10% 40px 10%',lg:'0 15% 50px 15%'}} position={'relative'} >
				<Letter letter={"B"} fontSize={'23vw'} left={'-5%'}/>
				<Stripes z0={-1} z2={'-1'}/>
				<Box zIndex={1} id={'posts'}>
					<PageBlogPosts page={props.page} context={props.context}/>
				</Box>
			</Box>
		</>
	)
}

export const query = graphql`
  fragment PageDefaultInformation on WpPage {
	content
  }
`